<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.2"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%"
  >
    <div style="float: right; margin-bottom: 3px" v-if="!showFilter && !__byItem"><b-button size="lg" variant=""  @click="showFilter = true"> Filtre</b-button></div>
    <div style="clear: both"></div>

    <div class="row serachPanel" v-if="__editMode === false && showCmds === -1 && showBons === -1 && showFilter" v-on:dblclick="showFilter = false">
      <div class="col-md-2"><selector _id="6653d8d40a156ff56b75be3a" :_editable="true" :_asFilter="true" :_initFn="setTypeData" :_parentItem="filterContainer" _warpper="filter_type"></selector></div>
      <div class="col-md-4"><span class="title">Recherche</span><base-input placeholder="nom, email, tél..." v-model="filterContainer.keySearch" type = "string"></base-input></div>
      <div class="col-md-2"><selector _id="665f92201563cec1256b82fa" :_editable="true" :_asFilter="true" :_initFn="setCarteOlaData" :_parentItem="filterContainer" _warpper="filter_carteOla"></selector></div>
      <div class="col-md-2"><selector _id="6653d9d70a156ff56b75be40" :_editable="true" :_asFilter="true" :_initFn="setStationsData" :_parentItem="filterContainer" _warpper="filter_stationID"></selector></div>
      <div class="col-md-2"><selector _id="665f9492ca0c5b91a6d82d7d" :_editable="true" :_asFilter="true" :_initFn="setPackData" :_parentItem="filterContainer" _warpper="filter_packId"></selector></div>
    </div>


    <div class="row" style="display: contents;">
    <div
      
      v-for="(item, index) in filtredList()"
      :key="index"
      :style=""
      :class="styleSublisteMode + isCurrent(index === showList, (showCmds === item._id || showBons === item._id))"
      v-if="((__idxOnEdition === -1 && showCmds === -1 && showBons === -1) || (__idxOnEdition === index) || (showCmds === item._id) || (showBons === item._id))"
    >

    <div class="row" style="align-items: center;">
      <div class="col-md-1">
        <b-icon :icon="((item.type === 'Société')? 'bank2': 'person-circle')" :style="((item.type === 'Société')? 'color:green': 'color:#151c37')"></b-icon>
        <user style="float: right" :PID="item._id" TYPE="CLIENT" :LOGIN="item.tel"></user> 
      </div>
      <div class="col-md-2">
        <div v-if="showMenu !== index" >{{ item.name }}</div>
        <div v-if="showMenu === index">
          <h4 style="margin-bottom: 5px">{{ item.name }}</h4>
          {{ item.email }}<br>
          {{ item.tel }}<br>
          {{ item.adress }}<br>
        </div>

        <div v-if="showMenu === index && item.type === 'Société'">
          <h4 style="margin-bottom: 5px">{{ item.sosiete }}</h4>
          {{ item.mf }}<br>
          {{ item.abreviation }}<br>
        </div>

        <div v-if="showMenu === index && item.cars.length>0">
          <h4 style="margin-bottom: 5px">Voitures</h4>
          <div><img v-for="(p, idxP) in item.cars" :key="idxP" :src="getTof(p.pic)" style="width: 80px; float: left; margin-right: 3px" /></div>
        </div>
      </div>
      <div class="col-md-1"> <b-icon :icon="((item.carteOla === 'Oui')? 'file-check-fill': 'file-check')" :style="((item.carteOla === 'Oui')? 'color:green': 'color:gray')"></b-icon></div>
      <div class="col-md-1">{{ item.stationeName }}</div>
      <div class="col-md-2">{{ item.packId }}</div>
      <div class="col-md-1"> <b-icon icon="basket-fill" style="color:#151c37"></b-icon> {{ item.nbrAchat }}</div>
      <div class="col-md-1"><b-icon icon="truck" style="color:#151c37"></b-icon>  {{ item.nbrVoiture }}</div>
      <div class="col-md-1">
        <b-icon icon="caret-up-fill" style="color:green"></b-icon> {{ item.nbrPoints.solde }}
        <b-icon icon="caret-down-fill" style="color:red"></b-icon> {{ item.nbrPoints.converti }}
      </div>     
      <div class="col-md-2">
        <div class="menu"  v-if="showCmds !== -1 || showBons !== -1">
            <b-button size="lg" variant="" @click="showCmds = -1; showBons = -1">
            Retour
          </b-button>
        </div>

        <div class="menu"  v-if="showCmds === -1 && showBons === -1">
                <ico-btn
          size="p"
          type="warning"
          icone="trash"
          :cb="
            () => {
              dispatchDelete(item._id);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          alt="info"
          type="toggleOn"
          icone="info-circle-fill"
          :cb="
            () => {
              toggleMenu(index);
            }
          "
        ></ico-btn>

        <ico-btn
          size="p"
          type="simple"
          icone="gear-fill"
          :cb="
            () => {
              dispatchEdit(item._id);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          type="simple"
          icone="list-check"
          :cb="
            () => {
              showCmds = item._id;
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          type="simple"
          icone="recycle"
          :cb="
            () => {
              showBons = item._id;
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          type="simple"
          icone="chat-right-dots-fill"
          :cb="
            () => {
              dispatchEdit(item._id);
            }
          "
        ></ico-btn>
      </div>
      
      <div style="float: left; width:80%; margin:10%" v-if="showMenu === index"><qrcode-vue size="150" :value="__.getAppShopPath()+item._id" level="H"  /></div>
      </div>
    </div>
    </div>
    </div>

    <cmds v-if="showCmds !== -1" :byClient="showCmds"></cmds>
    <bons v-if="showBons !== -1" :byClient="showBons"></bons>
  </b-overlay>
</template>
<script>
import user from '@/pages/utilisateurs/index';
import cmds from '@/pages/cmds/index';
import bons from '@/pages/bons/index';
import QrcodeVue from 'qrcode.vue'
export default {
  components: {
    user,
    bons,
    cmds,
    QrcodeVue
  },
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    __catList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      filterContainer: { filter_type: '', filter_carteOla: '', filter_stationID: '',filter_packId: '', keySearch: '' },
      showCmds: -1,
      showBons: -1,
      structure: { type: [], stations: []},
      fullList: [],
      showMenu: -1,
      showList: -1,
      showFilter: true,
    };
  },
  methods: {
    getStationName(id) {
      if(this.structure?.stations?.length>0) {
        var res = this.structure.stations.filter(s => s.value === id)
        return res[0].text
      }
      return  '';
    },

    filtredList() {
      var ret = this.fullList;
      if(this.filterContainer.filter_type)
        ret = ret.filter(e => e.type === this.filterContainer.filter_type)
      if(this.filterContainer.filter_carteOla)
        ret = ret.filter(e => e.carteOla === this.filterContainer.filter_carteOla)
      if(this.filterContainer.filter_stationID)
        ret = ret.filter(e => e.stationID === this.filterContainer.filter_stationID)
      if(this.filterContainer.filter_packId)
        ret = ret.filter(e => e.packId === this.filterContainer.filter_packId)
        
      if(this.filterContainer.keySearch)
        ret = ret.filter(e => e.name.includes(this.filterContainer.keySearch) ||
        e.email.includes(this.filterContainer.keySearch) ||
        e.societe.includes(this.filterContainer.keySearch) ||
        e.abreviation.includes(this.filterContainer.keySearch) ||
        e.mf.includes(this.filterContainer.keySearch) ||
        e.tel.includes(this.filterContainer.keySearch)
        )
      return ret
    },

    setTypeData(list){
      this.structure.type = list
    },

    setCarteOlaData(list){
      this.structure.carteOla = list
    },

    setStationsData(list){
      this.structure.stations = list
    },

    setPackData(list){
      this.structure.pack = list
    },


    isCurrent(current, showCmd) {
      var _showCmd = showCmd ? ' withSub' : '';
      if (this.showList !== -1) return current+_showCmd ? "  actif" : " flowted";
      return _showCmd;
    },

    toggleMenu(index) {
      if (this.showMenu != index) {
        this.showMenu = index;
      } else {
        this.showMenu = -1;
      }
    },

    toggleList(index) {
      if (this.showList != index) {
        this.showList = index;
      } else {
        this.showList = -1;
      }
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx);
          }
        });
    },

    dispatchAdd() {
      this.$parent.addAction();
    },

    getTof(c) {
      return c ? this.__.getAppPath()+(c.split('./').join('')) : '';
      // return "http://localhost:5000/uploads/" + c.split("/uploads/")[1];
      // return "./uploads/" + c.split("/uploads/")[1];
    },

    formatList() {

      this.fullList = this.fullList.map(e => {
        return {
          ...e,
          stationeName: this.getStationName(e.stationID)
        }
      })
    }

  },
  
  computed: {
    styleSublisteMode() {
      return this.__editMode !== true ? "listeMode col-md-12" : "editMode col-md-12";
    },
  },

  watch: {
    __list: {
      deep: true,
      handler(newValue, oldValue) {
        this.fullList = this.$props.__list ;
        this.formatList()
      },
    },
    // structure: {
    //   deep: true,
    //   handler(newValue, oldValue) {
    //     this.formatList()
    //   },
    // },
  },
  mounted() {
    
    //this.formatList()
  },
};
</script>
<style scoped>
.editMode {
  float: left;
  padding: 3%;
  margin-bottom: 10px;
  border-radius: 30px;
  min-height: 40px;
  min-width: 100%;
  background-color: #fff;
}
.editMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
    max-width: 170px;
}
.listeMode {
  padding: 3%;
  margin-bottom: 20px;
  border-radius: 30px;
  min-height: 50px;
  background-color: #fff;
}
.listeMode .menu {
  float: right;
  border-radius: 30px;
  background-color: #e9e9e9;
  max-width: 170px;
  min-height: 120px;
  padding: 10px
}
@media (max-width: 1024px) {.listeMode .menu {max-width: 70px;min-height: 325px;}}

.pic {
  width: 17%;
  margin-right: 3%;
  float: left;
}
.pic img {
  border-radius: 50%;
}
.preview {
  float: left;
  font-weight: bolder;
}

.preview em {
  font-weight: lighter;
  font-size: 12px;
}

.withSub {
background-color: rgb(255 255 255);
    color: black !important;
    border: 3px dotted #151c37;
}
.withSub .menu {
  background-color: #ebebeb !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    border-radius: 10px;
}
.flowted {
  filter: blur(3.5px);
}

.actif {
  filter: none;
  background-color: #131016;
}

.shop {
  margin-right: 5px;
  color: #2bffc6;
}
.service {
  margin-right: 5px;
  color: #ff8d72;
}
.lubrifiant {
  margin-right: 5px;
  color: #ffd600;
}

.carburant {
  margin-right: 5px;
  color: #b1cf0c;
}

.serachPanel {
  padding: 3%;
  background-color: #171f3c;
  border-radius: 30px;
  margin:20px 0;
}
</style>
