<template>
  <b-overlay
      :show="isOnLoad()"
      variant="white"
      rounded
      opacity="0.20"
      spinner-variant="primary"
      spinner-big
      style="float: left; width: 100%"
    >
    <div v-if="clientID === ''" class="row">
      <div class="col-md-9">
        Chercher par numéro de téléphone
        <base-input placeholder="Chercher" v-model="keySearch" type="string">
        </base-input>
      </div>
      <div class="col-md-3">
        <br />
        <b-button size="sm" @click="loadData()">OK</b-button>
      </div>
    </div>

    <div v-if="clientID !== '' && voitureID === ''" class="row">
      <div class="col-md-12">
        Séléctionner la voiture de {{ client.name }}<br /><br />
        <div
          class="singleCar"
          v-for="(v, index) in voitures"
          @click="selectCar(v._id)"
        >
        <img :src="getTof(v.pic1)" />
        <h1 style="margin-top: 15px; margin-bottom: 15px; font-size:17px; text-align:center">
              {{ v.matricule }}
            </h1>
        </div>
      </div>
    </div>

    <div v-if="voitureID !== '' && serviceID === ''" class="row">
      <div class="col-md-12">
        <b-button size="sm" variant="danger" @click="voitureID = ''">
        Retour
      </b-button>
        Séléctionner le type d'achat<br /><br />
        <div
          class="singleService"
          v-for="(s, index) in services"
          @click="selectService(s.id)"
        >
        
            <b-icon
              :icon="s.icone"
              :class="getClass(s.id)"
              style="width: 30px; height: 30px; text-align: center; margin: 10px"
            ></b-icon>
          <h1 style="margin-top: 15px; margin-bottom: 15px; font-size:17px; text-align:center">{{ s.label }}</h1>
        </div>
      </div>
    </div>

    <div v-if="serviceID !== '' && article === null" class="row">
      <b-button size="sm" variant="danger" @click="serviceID = ''">
        Retour
      </b-button>
      <div class="col-md-12">
        <prods
          :modeAchat="serviceID"
          :suggestion="suggestedProducts()"
          :onSelect="onSelectProd"
        ></prods>
      </div>
    </div>

    <div v-if="article !== null" class="row">
      
      <div class="col-md-12">
        <b>{{ client.name }}</b> <br /><b-icon
          icon="currency-dollar"
          style="color: red"
        ></b-icon>
        Solde {{ client.solde }} points <br />
        <b-icon icon="caret-up-fill" style="color: green"></b-icon> Nouveau
        solde {{ client.solde + collectedPts }} points
        <div v-if="bons.length>0" style=" background-color: black; padding: 10px; border-radius: 10px; float: left; width: 100%">
        <b>Bon d'achat : </b><b-form-select
          v-model="consumedBon"
          :options="bons"
          class="form-control"
          style="width:50%; float: right"
      ></b-form-select>
        </div>
      </div>

      
      <div
        v-for="(conf, idx) in article.template"
        class="col-md-12"
      >
        <b v-if="conf.flagType !== 'SELECTOR' && conf.flagVisible">{{conf.flagName}}</b>
        <base-input
          v-if="conf.flagType === 'IMP_NUM' && conf.flagVisible"
          :placeholder="conf.flagName"
          v-model="cmdData[idx].val"
          type = "num"
          :range = "{min: 1}"
          :error = "errorCtrl"
          @change="updateSolde()"
        >
        </base-input>
        
        <base-input
          v-if="conf.flagType === 'IMP_STR' && conf.flagVisible"
          :placeholder="conf.flagName"
          v-model="cmdData[idx].val"
          type = "str"
          :error = "errorCtrl"
          @change=""
        >
        </base-input>

        <b-form-datepicker v-if="conf.flagType === 'IMP_DAT' && conf.flagVisible" id="datepicker-dateVT" v-model="cmdData[idx].val" :dark=true class="mb-1" :placeholder="conf.flagName" nav-button-variant="secondary"></b-form-datepicker>

        <selector v-if="conf.flagType === 'SELECTOR' && conf.flagVisible" :_id="conf.flagConf" :_customTitle="conf.flagName" :_editable="true"   :_parentItem="cmdData[idx]" _warpper="val" _customCss="background-color:#0e101a; padding: 3%; border-radius: 10px; float: left"></selector>
      </div>

      <div class="col-md-12">
      <b-button size="sm" variant="danger" @click="article = null">
        Retour
      </b-button>

      <b-button style="float: right" size="sm" variant="success" @click="saveCmd()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Valider l'achat</b-button>
      </div>

    </div>
  </b-overlay>
</template>

<script>
import Vue from "vue";
import prods from "@/pages/prods/index.vue";
export default {
  components: {
    prods,
  },
  data() {
    return {
      keySearch: "",
      clientID: "",
      voitureID: "",
      serviceID: "",
      article: null,
      cmdData: [],
      client: {},
      stationID: "",
      onGoingReq: [],
      bons: [],
      voitures: [],
      errorCtrl: [],
      consumedBon: '',
      ratio: {unit: 1, pts: 0, pack : ''},
      services: [
        {
          label: "Boutique",
          id: "Shop",
          icone: "shop",
        },
        {
          label: "Service",
          id: "Service",
          icone: "tools",
        },
        {
          label: "Carburant",
          id: "Carburant",
          icone: "speedometer",
        },
        {
          label: "Lubrifiant",
          id: "Lubrifiant",
          icone: "wrench",
        },
      ],
    };
  },
  methods: {
    isOnLoad() {
      this.onGoingReq.length > 0;
    },

    getClass(type) {
      switch (type) {
        case "Shop":
          return "shop";
        case "Service":
          return "service";
        case "Carburant":
          return "carburant";
        case "Lubrifiant":
          return "lubrifiant";
      }
      return "shop";
    },

    selectCar(id) {
      this.voitureID = id;
    },

    selectService(id) {
      this.serviceID = id;
    },

    suggestedProducts() {
      return this.voitures
        .filter((v) => v._id === this.voitureID)
        .map((v) => [v.huileId, v.carbuId]);
    },

    onSelectProd(prd) {
      this.article = prd;
      this.getRatioSolde();
      
      this.cmdData = this.article.template.map(a => ({
        title: a.flagName,
        id: a.flagID,
        val: (a.flagType=== 'SELECTOR')? [] : a.flagValue,
      }))

      this.updateSolde();
    },

    getRatioSolde() {
      const ratio = this.article?.config.filter(p => p.pack === this.client.packId)
      if(ratio.length>0) {
        this.ratio = ratio[0]
      }
    },

    getQteFromData() {
      var qte = this.cmdData.filter(c => c.id ==="qte")[0].val
      if(!qte)
        return 1;
      return Number(qte)
    },

    updateSolde() {
      this.collectedPts = Math.floor(this.getQteFromData() / this.ratio.unit) * this.ratio.pts
    },

    saveCmd() {
      
      const agent = Vue.$cookies.get("agent");
      this.onGoingReq.push({});
      let $onNext = (response) => {
       this.onSaveCmd();
       this.updateClientSolde(response.data)
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'PUT',
        route: '/cmd',
        data: {
          clientName: this.client.name,
          clientNumber: this.client.tel,
          clientPack: this.client.packId,
          stataionName: this.stationID,
          agentName: agent.name,
          articleName: this.article.name,
          voitureName: this.voitures.filter(v => v._id === this.voitureID)[0].matricule,
          data: this.cmdData,
          solde: this.collectedPts,
          articleType: this.article.catTitle,
          voitureID: this.voitureID,
          articleID: this.article._id,
          agentID: agent._id,
          stationID: this.stationID,
          clientID: this.clientID,
          voitureType: this.voitures.filter(v => v._id === this.voitureID)[0].typeId,
        },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    getBonSolde() {
      var sold = this.bons.filter(b => b.value === this.consumedBon)
      if(sold.length>0) {
        return sold[0].extra
      }
      return 0
    },

    updateClientSolde(cmdID) {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.client.solde = this.client.solde + this.collectedPts;
        if(this.consumedBon !== '')
          this.updateBon(cmdID)
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'POST',
        route: '/clients',
        data: {
          solde: this.collectedPts+this.client.solde,
          _id: this.client._id,
        },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    useBon() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.client.solde = this.client.solde - this.getBonSolde();
        this.loadBons()
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'POST',
        route: '/clients',
        data: {
          solde: this.client.solde - this.getBonSolde(),
          usedBon: this.consumedBon,
          _id: this.client._id,
        },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    updateBon(cmdID) {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.useBon()
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'POST',
        route: '/bons',
        data: {
          _id: this.consumedBon,
          consumed: true,
          consumedDate: new Date(),
          currentSolde:  this.client.solde,
          cmdId: cmdID,
        },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    onSaveCmd() {
      this.$bvModal.msgBoxConfirm(
              "Opération enregistrée, ajouter une nouvelle commande?",
              {
                title: "Confirmation",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Oui",
                cancelTitle: "Non",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: false,
              }
            ).then(v => {
              if(v === false) {
                this.keySearch = "";
                this.clientID = "";
                this.voitureID = "";
                this.serviceID = "";
                this.article = null;
                this.cmdData = [];
                this.client = {};
                this.voitures = [];
                this.ratio = {unit: 1, pts: 0, pack : ''};
              } else {
                this.serviceID = "";
                this.article = null;
                this.cmdData = [];
                this.ratio = {unit: 1, pts: 0, pack : ''};
              }
            });
    },

    getTof(c) {
      return c ? this.__.getAppPath()+(c.split('./').join('')) : '';
      // return "http://localhost:5000/uploads/" + c.split("/uploads/")[1];
      // return "./uploads/" + c.split("/uploads/")[1];
    },

    loadBons() {
      let $onNext = (response) => {
        
        if (response.data.length > 0) {
          this.bons = response.data.filter(b => b.confirmed === true && b.consumed === false).map(e=> ({
            value: e._id,
            text: e.values+ " TND",
            extra: e.nbrpts
          }))
          if(this.bons.length>0)
            this.bons = [{value: -1, text: '.....'}].concat(this.bons)
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: "GET",
        route: "/bons/client/" + this.clientID,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadCars() {
      let $onNext = (response) => {
        this.voitures = [
          {
            _id: '6695a8cbcd05aee3bf2daf5b',
            matricule: "Autre voiture",
            type: "autre",
            pic1: this.__.getAppPath()+"uploads/catalogue/default.jpg",
          },
        ];
        if (response.data.length > 0) {
          this.voitures = response.data.concat([
            {
              _id: '6695a8cbcd05aee3bf2daf5b',
              pic1: this.__.getAppPath()+"uploads/catalogue/default.jpg",
              matricule: "Autre voiture",
              type: "autre",
            },
          ]);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: "GET",
        route: "/cars/list/" + this.clientID,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData() {
      let $onNext = (response) => {
        if (response.data.length > 0) {
          this.client = response.data[0];
          if(this.client.stationID !== this.stationID) {
            this.$bvModal
            .msgBoxOk("Ce client n'est pas autorisé a cette station", {
              title: "Information",
              size: "lg",
              buttonSize: "lg",
              okVariant: "danger",
              okTitle: "Confirmer",
              cancelTitle: "Annuler",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: false,
            })

          } else {
            this.clientID = this.client._id;
            this.loadCars();
            this.loadBons();
          }
        } else {
          this.$bvModal
            .msgBoxOk("Client introuvable", {
              title: "Information",
              size: "lg",
              buttonSize: "lg",
              okVariant: "danger",
              okTitle: "Confirmer",
              cancelTitle: "Annuler",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: false,
            })

        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      if (this.keySearch !== "") {
        this.__.httpAction({
          methode: "GET",
          route: "/clients/search/",
          data: { label: this.keySearch },
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
        return;
      }
      this.__.httpAction({
        methode: "GET",
        route: "/clients/" + this.clientID,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    init() {
      this.stationID = Vue.$cookies.get("station");
      if (this.$route.params.id !== undefined) {
        this.clientID = this.$route.params.id;
        this.loadData();
      }
    },
  },
  mounted() {
    if(Vue.$cookies.get("station")) {
      this.init()
    } else {
      this.$bvModal
            .msgBoxOk("Session expirée, veuillez vous connecter a votre compte agent", {
              title: "Information",
              size: "lg",
              buttonSize: "lg",
              okVariant: "danger",
              okTitle: "Fermer",
              cancelTitle: "Annuler",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: false,
            })
    }
    
  },
};
</script>
<style scoped>
.singleService {
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  background-color: #252935;
  border-radius: 5px;
  float: left;
  width: 46%;
  padding: 1%;
  margin: 1%;
}
.singleCar {
  cursor: pointer;
  margin-bottom: 5px;
  background-color: #252935;
  border-radius: 5px;
  float: left;
    width: 46%;
    padding: 1%;
    margin: 1%;
}
.shop {
  color: #2bffc6;
}
.service {
  color: #ff8d72;
}
.lubrifiant {
  color: #ffd600;
}

.carburant {
  color: #b1cf0c;
}
</style>
