<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%;"
  >
  
    <div :class="styleSublisteMode+isCurrent(index === __idxOnEdition)" v-for="(item, index) in __list" :key="index">
      <div class="subPreview" @click= "showMenu = ((showMenu === index) ? -1 : index)">
        {{ item.name}}
      </div>

      <div class="subMenu" v-if="showMenu === index">
        <ico-btn size="p" type="simple" icone="gear-fill" :cb="()=>{dispatchEdit(item._id)}"></ico-btn>
        <ico-btn size="p" type="toggleOn" custom="" icone="eye-slash-fill" v-if=" item.isActive == true" :cb="()=>{dispatchDesactive(item._id)}"></ico-btn>
        <ico-btn size="p" type="toggleOff" icone="eye-fill" v-if=" item.isActive == false" :cb="()=>{dispatchActive(item._id)}"></ico-btn>
        <ico-btn size="p" type="warning" icone="trash" :cb="()=>{dispatchDelete(item._id)}" v-if="__editMode == false"></ico-btn>
        <ico-btn size='p' type="simple" icone="list-stars" :cb="()=>{showSub(item._id)}" v-if="__type == 'EXT_DD'"></ico-btn>
      </div>
    </div>
  </b-overlay>
</template>
<script>

export default {
  components: {
  },
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __type: {
      type: String,
      default: () => {
        return 'DD';
      },
    },
  },
  
  data() {
    return {
      showMenu: -1,
    };
  },
  methods: {
    showSub(id) {
      this.$parent.showSub(id);
    },

    isCurrent(current) {
      return current && this.__editMode ? ' actif' : '';
    },

    toggleMenu(index) {
      if(this.showMenu != index ){this.showMenu = index} else {this.showMenu =-1}
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchActive(idx) {
      this.$parent.toggleActivateAction(idx,1);
    },

    dispatchDesactive(idx) {
      this.$parent.toggleActivateAction(idx,0);
    },

    dispatchDelete(idx) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'lg',
        buttonSize: 'lg',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.$parent.deleteAction(idx);
          }
        })
    },

    dispatchAdd() {
      this.$parent.addAction();
    },

  },

  computed: {
    styleSublisteMode() {
      return (this.__idxOnEdition !== -1) ? 'sublisteModeFull' : 'sublisteMode';
    },
  },

  watch: {
    __isOnLoad: function () {
    },
  },
};
</script>
<style>
.actif{
  opacity: 1 !important;
}
.sublisteModeFull {
  width: 25%;
    float: left;
    margin-bottom: 10px;
    border-radius: 10px;
    border: dotted 1px #252c3c;
    opacity: 0.3;
}
.sublisteModeFull .subMenu {
  width: 60%;
    float: left;
    padding: 5px;
    border-radius: 10px;
}
.sublisteModeFull .subPreview {
  width: 40%;
    float: left;
    padding: 10px;
    border-radius: 10px;
}
.sublisteMode {
    width: 30%;
    float: left;
    margin: 0 10px 20px;
    border-radius: 10px;
    border: dotted 1px #252c3c;
    padding: 15px;
}
.sublisteMode .subMenu {
    width: 40%;
    float: right;
    border-radius: 10px;
}
.sublisteMode .subPreview {
  float: left;
    width: 60%;
    border-radius: 0px;
    cursor: pointer;
}
.subPreview:hover {
    font-weight: 500;
}
</style>
