<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >

      <div class="editionPanel">
        <div class="row">
          <div class="col-md-12">
            <div class="row">  
              <div class="col-md-6">
                <selector _id="6653d9d70a156ff56b75be40"  :_editable="true"   :_parentItem="__item" _warpper="stationID" _customCss="padding: 2%;  float: left"></selector>
              </div>
              <div class="col-md-6 pr-md-1">
                <base-input
                  placeholder="Nom et prénom"
                  v-model="__item.name"
                  type = "string"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>
              <div class="col-md-6 pr-md-1">
                <base-input
                  placeholder="Email"
                  v-model="__item.email"
                  type = "string"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>

              <div class="col-md-6 pr-md-1">
                <base-input
                  placeholder="Téléphone"
                  v-model="__item.tel"
                  type = "number"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>


            

            </div>
          </div>



          <div class="col-md-12">
            <b-button size="lg" variant=""  @click="dispatchCancel()"> Fermer </b-button>
            <b-button size="lg" variant="" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
          </div>


        </div>

      </div>
    </b-overlay>
  </div>
</template>
<script>

export default {
  components: {
  },

  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      isSte: false,

      errorCtrl: [],
    }
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },

  },
  mounted() {
  },

  watch: {
  },


};
</script>
<style scoped>
.col-md-3 {
  padding-left:1px !important;
  padding-right:1px !important;
}
.modal-xl {
  max-width: 85% !important;
}
.editionPanel {
    width: 100%;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #ffffff;
}
.form-control option {
  color: #1b1b29;
}
</style>
