<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >
        <card>
                   
          <div class="row" style="margin-left: 0px;">

            <div class="col-md-12" style="float: left ;">
              <b-form-checkbox
                id="checkbox-1"
                v-model="__item.isRoot"
                name="checkbox-1"
                value=1
                unchecked-value=0
              >
                super admin user
              </b-form-checkbox>
            </div>

            <div class="col-md-9" style="float: left ;">
              <base-input
                placeholder="Nom prénom"
                v-model="__item.name"
              >
              </base-input>
            </div>
            
            <div class="col-md-3" style="float: right ;" v-if="__item.isRoot == 0">
              <b-form-select v-model="__item.clientID" class="form-control" :options="clientArray">
              </b-form-select>
            </div>

            <div class="col-md-12" style="float: left ;">
              <base-input
                placeholder="Email"
                v-model="__item.email"
              >
              </base-input>
            </div>

            <div class="col-md-12 pr-md-1" style="float: left ;">
              <base-input
                placeholder="login"
                v-model="__item.login"
              >
              </base-input>
            </div>

            <div class="col-md-12" style="float: left ;">
              <base-input
                placeholder="Mot de passe"
                v-model="__item.pwd"
              >
              </base-input>
            </div>

            <div class="col-md-12" style="float: left ;" v-if="__item.isRoot == 0">
            <b-form-group
            label="Afficheurs a gérer"
            v-slot="{ grpId }"
            style="margin-bottom: 0px;"
            >
              <b-form-checkbox-group
                v-model="__item.afficheursIDs"
                :options="afficheursArray.filter(e => e.cid === __item.clientID)"
                :aria-describedby="grpId"
                switches
                size="sm"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
            </div>
            </div>
          
          <div class="text-right">
            <b-button variant="" @click="dispatchCancel()"> Fermer </b-button>
            <b-button variant="" @click="dispatchSave()">
              Enregistrer
            </b-button>
          </div>
        </card>
        </b-overlay>
  </div>
</template>
<script>

export default {
  components: {

  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clientArray: {
      type: Array,
      default: () => {
        return []
      }
    },
    afficheursArray: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => {
    return {
    }
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
  },
  mounted() {
  }
};
</script>
<style>
</style>
