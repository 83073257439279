<template>
  <div class="row">
    <div class="col-md-12" v-if="onEditionId !== null">
      <Edit
        :__item="onEditionItem"
        :__isOnLoad="isEditOnload"
        :__newInscri="isNewInscri()"
      ></Edit>
    </div>
    <ico-btn class="btn-plus-pos" type="simple" icone="plus-circle" :cb="()=>{addAction()}" v-if="!isNewInscri()"></ico-btn>
    <div :class="listStyle" v-if="!isNewInscri()">
      <List
        :__list="dataArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idxOnEdition="this.onEditionIdx"
        ></List>
    </div>
  </div>
</template>
<script>
import Edit from './Edit';
import List from './List';
export default {
  components: {
    Edit,
    List,
  },
  props: {
    NEW_CLT:  {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },

  data() {
    return {
      moduleRoute: '/clients',
      onEditionId: null,
      onEditionIdx: -1,
      onEditionItem: null,
      isListOnload: false,
      isEditOnload: false,
      dataArray: [],
      onGoingReq:[],
    };
  },

  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-12' : 'col-md-12';
    },
  },

  methods: {
    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
    },

    saveAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      let diff = this.__.differ(
        this.dataArray[this.onEditionIdx],
        this.onEditionItem
      );
      diff.push('_id');
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    toggleActivateAction(id, val) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.dataArray[idx].isActive = val;
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id', 'isActive']);
      data.isActive = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    deleteAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        if (response.data == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute+"/"+data._id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    editAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx)
      this.onEditionId = id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    addAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(response.data, () => {
          this.createFolder(response.data);
          this.editAction(response.data);
        }
        );
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    createFolder(id) {
      let $onNext = (response) => {};

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'PUT',
        route: '/folders',
        data: {parentID: id},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = response.data[0];
          } else {
            this.dataArray.push(response.data[0]);
          }

          if (cb) cb();
        } else {
          this.dataArray = response.data;
        }
        this.dataArray = this.dataArray.map((e, index) => {
          var ret = {
            ...e,
            cars: [],
            nbrAchat: 0,
            nbrVoiture: 1,
            nbrPoints: {
              solde: e.solde,
              converti: 0,
              restant : 0
            }
          }
          this.loadCars(ret._id, index);
          this.loadCmds(ret._id, index);
          this.loadBons(ret._id, index)
          return ret
        })
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute+ (id != null ? '/'+id : '/'),
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadCars(id, container) {
      let $onNext = (response) => {
        this.dataArray[container].cars = response.data.map(e => {
          return {
            pic: e.pic1
          }
        })
        this.dataArray[container].nbrVoiture = response.data.length
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/cars/list/'+id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadCmds(id, container) {
      let $onNext = (response) => {
        this.dataArray[container].nbrAchat = response.data.length
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/cmd/client/'+id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadBons(id, container) {
      let $onNext = (response) => {
        response.data.map((b) => {
          if(b.consumed) {
            this.dataArray[container].nbrPoints.converti += Number(b.nbrpts)
          }
        })
        
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: '/bons/client/'+id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    isNewInscri() {
      return this.$props.NEW_CLT;
    }

  },
  mounted() {
    if(this.$props.NEW_CLT === true) {
      this.addAction();
      return
    }
    this.loadData();
  },

  watch: {
    onGoingReq: function () {
      this.isListOnload = this.isEditOnload = this.onGoingReq.length > 0;
    },
  },
};
</script>
<style></style>
