<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >
      <b-modal 
          v-model="showUploader"  
          :hide-footer= "true"
          size="xl"
          :hide-header= "true"
          >
            <galerie-manager :__payload="dataUploaderPayload"></galerie-manager>
          </b-modal>

      <div class="editionPanel">
        <div class="row">

          <div class="col-md-12 pr-md-1">
              <base-input
                placeholder="Titre"
                v-model="__item.name"
                type = "string"
                :error = "errorCtrl"
              >
              </base-input>
            </div>

          <div class="col-md-6 pr-md-1">
                <selector :_customData="categoryListStructure" :_showTitle='false' :_parentItem="__item" _warpper="catID" :_cb="setCatTitle"></selector>
            </div>

            <div class="col-md-6 pr-md-1">
                <selector :_customData="categorySubListStructure" :_showTitle='false'  :_parentItem="__item" _warpper="subcatID"></selector>
            </div>

            

            <div class="col-md-6 pr-md-1">
                <selector _id="6653d9d70a156ff56b75be40" :_parentItem="__item" _warpper="stationId" _customCss="background-color:#fff; padding: 3%; border-radius: 10px; float: left;border: 1px solid #ced4da"></selector>
            </div>

            <div class="col-md-6 pr-md-1 uploader_img" >
                <b-button @click="showUploader = true" v-if="__item.pic ===''" size="lg" >
                Parcourir
                </b-button>
                <img :src="getTof(__item.pic)" v-else @click="showUploader = true" style="max-width: 98%; margin:1%"/>
            </div>

            <div class="col-md-4 pr-md-1">
                <selector _id="6657a83d5b58518ed52044ad"  :_parentItem="__item" :_cb="onUpdatePack" _warpper="packID" _customCss="background-color:#fff; padding: 3%; border-radius: 10px; float: left;border: 1px solid #ced4da"></selector>
            </div>

            <div class="col-md-8">
              <div class="row" v-for="(conf, cIndex) in __item.config" :key="cIndex">
                <div class="col-md-4">{{conf.pack}}</div>
                <div class="col-md-4">
                  <base-input
                  placeholder="Nombre"
                  v-model="conf.pts"
                  type = "num"
                  :range = "{min: 0}"
                  :error = "errorCtrl"
                  ></base-input>
                </div>
                <div class="col-md-4">
                  <base-input
                  placeholder="Nombre"
                  v-model="conf.unit"
                  type = "num"
                  :range = "{min: 0}"
                  :error = "errorCtrl"
                  ></base-input>
                </div>
              </div>
            </div>


            <div class="col-md-12" >
              <templateManager :_customData="__item.template" _customCss="background-color: #e9ecef;padding: 5%;border-radius: 30px;margin: 25px 0;border: 1px solid #ced4da;"  v-on="{updateTpl: onUpdateTpl}"></templateManager>
            </div>


        </div>
        <div class="text-right">
          <div class="col-md-12" style="margin-top:5px;">
            
            <b-button size="lg" variant=""  @click="dispatchCancel()"> Fermer </b-button>
            <b-button size="lg" variant="" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },

    __catList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      _packID: [],
      fullList: [],
      showUploader: false,
      categoryListStructure: null,
      categorySubListStructure: null,
      dataUploaderPayload: {
        folder: "prods",
        onFireCB: () => {},
        multiSelection: false,
        customClass: "customCatEditModal"
      },
      errorCtrl: [],
    }
  },
  methods: {
    onUpdateTpl(tpl) {
      this.$props.__item.template = tpl.data;
    },

    getTof(c) {
      return c ? this.__.getAppPath()+(c.split('./').join('')) : '';
      // return 'http://localhost:5000/uploads/'+c.split("/uploads/")[1];
      // return './uploads/'+c.split("/uploads/")[1];
    },

    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },

    setCatTitle(value) {
      if(value.length>0)
        this.__item.catTitle = value[0].extra
    },

    onUpdatePack() {
      this.setConfig()
    },

    setConfig() {
      this.__item.config = this.__item.packID.map(e=> {
        var check = this.__item.config.filter(c => c.pack === e)
        if(check.length === 1) {
          return check[0] 
        }
          return {
          pack: e,
          unit: 0,
          pts: 0
          }
      });
    },

    setSubData() {
      if (this.__item.catID) {
          this.categorySubListStructure = {}
          this.categorySubListStructure.grp = {
            type: 'DD',
            name: ''
          }
          this.categorySubListStructure.list = this.fullList.filter(e => e.pId === this.__item.catID).map(c => ({text: c.name, value: c._id }));
          this.categorySubListStructure.editable = this.categorySubListStructure.list.length > 0;
        }
    },

    setCatList() {
      if(this.$props.__catList.length === 0) 
        return
      this.fullList = this.$props.__catList;
      this.categoryListStructure = {}
      this.categoryListStructure.editable = true;
      this.categoryListStructure.grp = {
        type: 'DD',
        name: 'Catégorie'
      }
      this.categoryListStructure.list = this.$props.__catList.filter(e => e.pId === '0').map(c => ({text: c.name, value: c._id, extra: c.type }));
      this.setSubData()
      },
  },
  mounted() {
    this.setCatList();
    this.dataUploaderPayload.clientID = '665d03b586d558945dfffa27';
    this.dataUploaderPayload.onFireCB = (params) => {
      this.showUploader = false
      this.__item.pic = params[0];
    }
    this._packID = this.__item.packID;
  },

  watch: {
    __item: {
      deep: true,
      handler(newValue, oldValue) {
          this.setSubData()
      },
    },
  },


};
</script>
<style>
.modal-xl {
  max-width: 85% !important;
}
.editionPanel {
    width: 100%;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #ffffff;
}
.form-control option {
  color: #1b1b29;
}
.uploader_img{
    margin: 0px 15px 15px;
    max-width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d1d1d1;
    border-radius: 25px

  }
</style>
