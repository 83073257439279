<template>
  
</template>

<script>
import Vue from "vue";
export default {

  mounted() {
    Vue.$cookies.remove("user")
    this.$router.push('/')
  },
};

</script>
