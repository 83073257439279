<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%;"
  >
  
    <div :class="styleSublisteMode+isCurrent(index === showList)" v-for="(item, index) in __list" :key="index" :style="" v-if="__idxOnEdition === -1 || __idxOnEdition === index">
      <div class="preview"><em style="font-weight: lighter; font-size:10px">{{ item._id }}</em> {{ item.name }}</div>

      <div class="menu" v-if="__idxOnEdition !== index && showMenu == index"> 
        <ico-btn size='p' type="simple" icone="gear-fill" :cb="()=>{dispatchEdit(item._id)}"></ico-btn>
        <ico-btn size='p' type="warning" icone="trash" :cb="()=>{dispatchDelete(item._id)}"></ico-btn>
        <ico-btn size='p' type="toggleOn" icone="reply-fill" :cb="()=>{toggleMenu(index)}"></ico-btn>
        
      </div>
      <div  v-if="__idxOnEdition !== index && showMenu != index" style="float: right">
        <b-icon icon="gear-fill" aria-hidden="true" @click="toggleMenu(index)"></b-icon>
      </div>

      <div style="float:right; color:#525f7f; margin-right:25px">
        <b-icon icon="list-stars" aria-hidden="true" @click="toggleList(index)"></b-icon>
      </div>

      <Opts :PID="item._id" v-if="showList === index" :TYPE="item.type"></Opts>
    </div>

  </b-overlay>
</template>
<script>
import Opts from '@/pages/opts/index';

export default {
  components: {
    Opts,
  },
  props: {

    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

  },
  
  data() {
    return {
      showMenu: -1,
      showList: -1,
    };
  },
  methods: {
    isCurrent(current) {
      if(this.showList !== -1) 
        return current ? '  actif' : ' flowted';
      return '';
    },

    toggleMenu(index) {
      if(this.showMenu != index ){this.showMenu = index} else {this.showMenu =-1}
    },

    toggleList(index) {
      if(this.showList != index ){this.showList = index} else {this.showList =-1}
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },


    dispatchDelete(idx) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'lg',
        buttonSize: 'lg',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.$parent.deleteAction(idx);
          }
        })
    },

    dispatchAdd() {
      this.$parent.addAction();
    },
  },
  watch: {
    __isOnLoad: function () {

    },
  },
  computed: {
    styleSublisteMode() {
      return (this.showList !== -1) ? 'listeMode' : 'listeMode';
    },
  },
  mounted() {

  }
};
</script>
<style scoped>
.listeMode {
    width: 100%;
    float: left;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    min-height: 40px;
    background-color: #ffffff;
}
.listeMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}
.preview {
    float: left;
    font-weight:bolder;
    margin: 5px 10px;
}

.flowted {
    filter: blur(3.5px);
}

.actif {
    filter: none;
}
</style>