<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >
      <div class="editionPanel">
        <div class="row" style="padding:20px;margin-bottom: 15px;">
            <div class="col-md-12 pr-md-1">
              <base-input
                placeholder="Value"
                v-model="__item.name"
                type = "string"
                :error = "errorCtrl"
              >
              </base-input>
            </div>

          <div class="col-md-4 pr-md-1" >
              <b style="font-size: 10px;">Root</b>
              <b-form-select v-model="__item.needRoot" class="form-control" :options="[{text: 'Oui', value: true}, {text: 'Non', value: false}]">
              </b-form-select>
          </div>

          <div class="col-md-4 pr-md-1" >
              <b style="font-size: 10px;">Type</b>
              <b-form-select v-model="__item.type" class="form-control" :options="[{text: 'Radio', value: 'RADIO'},{ text: 'Template', value: 'SELECT_TPL'},{ text: 'Select', value: 'SELECT'}, { text: 'Dropdown', value: 'DD'}, {text: 'Deep DropDown', value: 'EXT_DD'}]">
              </b-form-select>
          </div>

          <div class="col-md-4 pr-md-1" >
              <b style="font-size: 10px;">Title</b>
              <b-form-select v-model="__item.useTitle" class="form-control" :options="[{text: 'Oui', value: true}, {text: 'Non', value: false}]">
              </b-form-select>
          </div>

        </div>
        <div class="text-right">
          <div class="col-md-12" style="margin-top:5px;">
            <b-button size="lg" variant=""  @click="dispatchCancel()"> Fermer </b-button>
            <b-button size="lg" variant="" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      errorCtrl: []
    }
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
  },
  mounted() {
  
  }
};
</script>
<style>
.editionPanel {
    width: 100%;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #ffffff;
}
.form-control option {
  color: #1b1b29;
}
</style>
