<template>
  <b-overlay
    :show="isOnLoad()"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%"
  >
    dfgdfg
  </b-overlay>
</template>

<script>
import Vue from "vue";
export default {
  components: {
    
  },
  data() {
    return {
      clientID: "",
      client: {},
      stationID: "",
      onGoingReq: [],
    };
  },
  methods: {
    isOnLoad() {
      this.onGoingReq.length > 0;
    },

    loadData() {
      let $onNext = (response) => {
        if (response.data.length > 0) {
          this.client = response.data[0];
        } 
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: "GET",
        route: "/clients/" + this.clientID,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    init() {
      this.stationID = Vue.$cookies.get("station");
      if (this.$route.params.id !== undefined) {
        this.clientID = this.$route.params.id;
        this.loadData();
      }
    },
  },
  mounted() {
    setTimeout(()=> {
      if (Vue.$cookies.get("station")) {
        this.init();
      } 
    }, 1000)
    
  },
};
</script>
<style scoped>
.singleService {
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  background-color: #252935;
  border-radius: 5px;
  float: left;
  width: 46%;
  padding: 1%;
  margin: 1%;
}
.singleCar {
  cursor: pointer;
  margin-bottom: 5px;
  background-color: #252935;
  border-radius: 5px;
  float: left;
    width: 46%;
    padding: 1%;
    margin: 1%;
}
.shop {
  color: #2bffc6;
}
.service {
  color: #ff8d72;
}
.lubrifiant {
  color: #ffd600;
}

.carburant {
  color: #b1cf0c;
}
</style>
