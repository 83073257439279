<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%;"
  >
  
    <div class="listeMode" v-for="(item, index) in __list.filter(e=>e.pId === '0')" :key="index">
      <div class="preview">
        <b-icon :icon="getIcon(item.type)" :class="getClass(item.type)"></b-icon>
        {{ item.name }}</div>

      <div class="menu" >
        <ico-btn size='p' type="simple" icone="gear-fill" :cb="()=>{dispatchEdit(item._id)}"></ico-btn>
        <ico-btn size='p' type="warning" icone="trash" :cb="()=>{dispatchDelete(item._id)}"></ico-btn>
        <ico-btn size='p' type="toggleOn" custom="blue" icone="plus-circle" :cb="()=>{dispatchAdd(item._id, item.type)}"></ico-btn>
      </div>
      <div style="clear:both"></div>
              <div class="sublisteMode" v-for="(subitem, subindex) in __list.filter(s=>s.pId === item._id)" :key="subindex" :style="">
              <div class="preview">{{ subitem.name }}</div>

              <div class="menu">
                <ico-btn size='p' type="simple" icone="gear-fill" :cb="()=>{dispatchEdit(subitem._id, subitem.pId)}"></ico-btn>
                <ico-btn size='p' type="warning" icone="trash" :cb="()=>{dispatchDelete(subitem._id), subitem.pId}"></ico-btn>
              </div>
            </div>
    </div>

  </b-overlay>
</template>
<script>

export default {
  components: {
  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

  },
  
  data() {
    return {
    };
  },
  methods: {
    getIcon(type) {
      switch(type) {
        case 'Shop':
          return 'shop';
        case 'Service':
          return 'tools';
        case 'Carburant':
          return 'speedometer';
        case 'Lubrifiant':
          return 'wrench';
      }
      return 'shop';
    },

    getClass(type) {
      switch(type) {
        case 'Shop':
          return 'shop';
        case 'Service':
          return 'service';
        case 'Carburant':
          return 'carburant';
        case 'Lubrifiant':
          return 'lubrifiant';
      }
      return 'shop';
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchAdd(id, pType) {
      this.$parent.addAction(id, pType);
    },

    dispatchDelete(idx) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'lg',
        buttonSize: 'lg',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.$parent.deleteAction(idx);
          }
        })
    },
  },
  watch: {
    __isOnLoad: function () {

    },
  },
  computed: {
  },
  mounted() {

  }
};
</script>
<style scoped>
.listeMode {
    width: 100%;
    float: left;
    padding: 3%;
    margin: 15px;
    border-radius: 30px;
    min-height: 40px;
    background-color: #fff;
}
.listeMode .menu {
  float: right;
  border-radius: 30px;
  background-color: #f0efef;
  max-width: 220px;
  min-height: 70px;
  padding: 10px
}
.listeMode .preview {
    font-weight:bolder;
    float: left;
    font-size: 18px;
}

.sublisteMode {
    width: 25%;
    float: left;
    margin-bottom: 10px;
    border-radius: 10px;
    border: dotted 1px #252c3c;
}
.sublisteMode .menu {
    width: 40%;
    float: right;
    border-radius: 10px;
}
.sublisteMode .preview {
    width: 40%;
    float: left;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.shop{
  color: #525f7f;
  margin-right: 10px;
}
.service{
  color: #525f7f;
  margin-right: 10px;
}
.lubrifiant{
  color: #525f7f;
  margin-right: 10px;
}
.carburant{
  color: #525f7f;
  margin-right: 10px;
}
</style>