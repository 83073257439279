<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.2"
    spinner-variant="primary"
    spinner-big
  >
  <div  v-if="__list.length === 0">
    <b-icon icon="person-plus" style="color:gray" @click="dispatchAdd()"></b-icon>
  </div>
  <div v-else>
    <b-icon icon="person-plus-fill" style="color:red" @click="dispatchDelete(__list[0]._id)"></b-icon>
    <b-icon icon="shield-fill-check" v-if=" __list[0].isActive == true" style="color:green" @click="dispatchDesactive(__list[0]._id)"></b-icon>
    <b-icon icon="shield-fill-x" v-if=" __list[0].isActive == false" style="color:orange" @click="dispatchActive(__list[0]._id)"></b-icon>
  </div>

  </b-overlay>
</template>
<script>
import { BaseButton } from '@/components';
export default {
  props: {

    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },

  },
  data() {
    return {};
  },
  methods: {
    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },
    dispatchActive(idx) {
      this.$parent.toggleActivateAction(idx,true);
    },
    dispatchDesactive(idx) {
      this.$parent.toggleActivateAction(idx,false);
    },
    dispatchDelete(idx) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'lg',
        buttonSize: 'lg',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.$parent.deleteAction(idx);
          }
        })
    },
    dispatchAdd() {
      this.$parent.addAction();
    },
  },
};
</script>
<style></style>
