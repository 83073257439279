<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >
      <div class="editionPanel">
        <div class="row">
            <div class="col-md-4 pr-md-1">
              <base-input
                placeholder="Value"
                v-model="__item.name"
                type = "string"
                :error = "errorCtrl"
              >
              </base-input>
            </div>
            <div class="col-md-4 pr-md-1">
                <selector _id="665ba0cb284f469df5af7980" :_parentItem="__item" _warpper="type" :_showTitle="false" :_editable="__item.pId==='0'"></selector>
            </div>

            <div class="col-md-4" >
              <b-button size="lg" style="float: right" variant=""  @click="dispatchCancel()"> Fermer </b-button>
              <b-button size="lg" style="float: right" variant="" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
            </div>

        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      errorCtrl: []
    }
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
  },
  mounted() {
  
  }
};
</script>
<style>
.editionPanel {
    width: 100%;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #ffffff;
}
.form-control option {
  color: #1b1b29;
}
</style>
