<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >

      <div class="editionPanel">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4">
                <selector _id="6653d8d40a156ff56b75be3a" :_editable="true" :_cb="setIsSte"  :_parentItem="__item" _warpper="type" _customCss="padding: 5%; float: left"></selector>
              </div>

              <div class="col-md-2">
                <selector _id="665f92201563cec1256b82fa" :_editable="true"  :_parentItem="__item" _warpper="carteOla" _customCss="padding: 5%; float: left"></selector>
              </div>

              <div class="col-md-3">
                <selector _id="6653d9d70a156ff56b75be40"  :_editable="true"   :_parentItem="__item" _warpper="stationID" _customCss="padding: 5%;  float: left"></selector>
              </div>
              <div class="col-md-3">
                <selector _id="665f9492ca0c5b91a6d82d7d"  :_editable="true"   :_parentItem="__item" _warpper="packId" _customCss="padding: 5% 13%;border-radius: 10px;float: left;border: 2px solid #141c37;"></selector>
              </div>
              <div class="col-md-4 pr-md-1">
                <base-input
                  placeholder="Nom et prénom"
                  v-model="__item.name"
                  type = "string"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>

              <div class="col-md-4 pr-md-1">
                <base-input
                  placeholder="Email"
                  v-model="__item.email"
                  type = "string"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>

              <div class="col-md-4 pr-md-1">
                <base-input
                  placeholder="Téléphone"
                  v-model="__item.tel"
                  type = "number"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>


              <div class="col-md-4 pr-md-1" v-if="this.isSte">
                <base-input
                  placeholder="Nom société"
                  v-model="__item.sosiete"
                  type = "string"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>

              <div class="col-md-4 pr-md-1" v-if="this.isSte">
                <base-input
                  placeholder="Matricule fiscal"
                  v-model="__item.mf"
                  type = "string"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>

              <div class="col-md-4 pr-md-1" v-if="this.isSte">
                <base-input
                  placeholder="Abreviation"
                  v-model="__item.abreviation"
                  type = "string"
                  :error = "errorCtrl"
                >
                </base-input>
              </div>

              <div class="col-md-12" style="clear: both;">
              <base-input>
                <textarea
                  rows="3"
                  cols="120"
                  class="form-control"
                  placeholder="Adresse"
                  v-model="__item.adress"
                >
                </textarea>
              </base-input>
            </div>

            </div>
          </div>

          <div class="col-md-6 ">

            <cars class="carsPanel" :clientID="__item._id" :embeded=true></cars>
          </div>

          <div class="col-md-12" v-if="!__newInscri">
            <b-button size="lg" variant=""  @click="dispatchCancel()"> Fermer </b-button>
            <b-button size="lg" variant="" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
          </div>

          <div class="col-md-12" v-else>
            <b-button size="lg" variant="danger"  @click="this.$route.path = '/users/eduardo'"> Annuler </b-button>
            <b-button size="lg" variant="success" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Confirmer l'inscription</b-button>
          </div>

        </div>

      </div>
    </b-overlay>
  </div>
</template>
<script>

import cars from '@/pages/cars/index.vue';
export default {
  components: {
    cars
  },

  props: {
    
    __newInscri: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },

    __catList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      isSte: false,

      errorCtrl: [],
    }
  },
  methods: {
    setIsSte() {
      this.isSte = this.__item.type === "Société"
    },

    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },

    setCatTitle(value) {
      console.log(value)
      if(value.length>0)
        this.__item.catTitle = value[0].extra
    },

    onUpdatePack() {
      this.setConfig()
    },

    setConfig() {
      this.__item.config = this.__item.packID.map(e=> {
        var check = this.__item.config.filter(c => c.pack === e)
        if(check.length === 1) {
          return check[0] 
        }
          return {
          pack: e,
          unit: 0,
          pts: 0
          }
      });
    },

    setSubData() {
      if (this.__item.catID) {
          this.categorySubListStructure = {}
          this.categorySubListStructure.grp = {
            type: 'DD',
            name: ''
          }
          this.categorySubListStructure.list = this.fullList.filter(e => e.pId === this.__item.catID).map(c => ({text: c.name, value: c._id }));
          this.categorySubListStructure.editable = this.categorySubListStructure.list.length > 0;
        }
    },

    setCatList() {
      if(this.$props.__catList.length === 0) 
        return
      this.fullList = this.$props.__catList;
      this.categoryListStructure = {}
      this.categoryListStructure.editable = true;
      this.categoryListStructure.grp = {
        type: 'DD',
        name: 'Catégorie'
      }
      this.categoryListStructure.list = this.$props.__catList.filter(e => e.pId === '0').map(c => ({text: c.name, value: c._id, extra: c.type }));
      this.setSubData()
      },
  },
  mounted() {
    this.setCatList();
    this.dataUploaderPayload.clientID = '665d03b586d558945dfffa27';
    this.dataUploaderPayload.onFireCB = (params) => {
      this.showUploader = false
      this.__item.pic = params[0];
    }
    this._packID = this.__item.packID;
  },

  watch: {
    __item: {
      deep: true,
      handler(newValue, oldValue) {
          this.setSubData()
      },
    },
  },


};
</script>
<style scoped>
.col-md-3 {
  padding-left:1px !important;
  padding-right:1px !important;
}
.carsPanel{
    background-color: #dbdbdb;
    margin-left: 2%;
    padding: 3% 0;
    max-width: 100%;
    border-radius: 30px;
}
.modal-xl {
  max-width: 85% !important;
}
.editionPanel {
    width: 100%;
    padding: 3%;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #ffffff;
}
.form-control option {
  color: #1b1b29;
}
</style>
