<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-overlay
        :show="__isOnLoad"
        variant="white"
        rounded
        opacity="0.20"
        spinner-variant="primary"
        spinner-big
      >

      <div class="editionZonePanel">
        <div class="row" style="margin-right: 0px; margin-left: 0px;">
          <div class="col-md-6 pr-md-1" style="float: right ;">
              <base-input
                placeholder="Message"
                v-model="__item.name"
                ></base-input>
          </div>

          <div class="col-md-6" style="margin-top:5px;">

<b-button size="lg" variant="" style="float: right" @click="dispatchSave()" v-if="(errorCtrl.filter(e => e.isValid === false).length === 0)">Enregistrer</b-button>
<b-button size="lg" variant="" style="float: right" @click="dispatchCancel()"> Fermer </b-button>
</div>
        </div>

      </div>
    </b-overlay>
  </div>
</template>
<script>

import axios from 'axios'
export default {
  components: {

  },
  props: {
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      errorCtrl: [],
      
    }
  },
  methods: {
    dispatchSave() {
      this.$parent.saveAction();
    },
    dispatchCancel() {
      this.$parent.cancelAction();
    },
  },
  mounted() {
  }
};
</script>
<style scoped>
.editionZonePanel {
    width: 100%;
    padding: 2%;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #252c3c;
}
.editionZoneParamsPanel {
    width: 100%;
    padding: 1%;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #121a2c;
}
.form-group {
  margin-bottom: 0px;
}
.modal.show .modal-dialog {
  max-width: 90% !important;
}
.modal-content {
  background: #fff !important;
}
</style>
