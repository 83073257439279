<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%"
  >
    <div class="row serachPanel" v-if="__editMode === false">
      <div class="col-md-4"><selector _id="6653d9d70a156ff56b75be40" :_editable="true" :_asFilter="true" :_initFn="setStationsData" :_parentItem="filterContainer" _warpper="filter_stationID"></selector></div>
      <div class="col-md-8"><span class="title">Recherche<br></span><base-input placeholder="nom, email, tél..." v-model="filterContainer.keySearch" type = "string"></base-input></div>
  <div style="float: right; margin-bottom: 3px" v-if="!showFilter && !__byItem"><b-button size="lg" variant=""  @click="showFilter = true"> Filtre</b-button></div>
   
   </div> <div style="clear: both"></div>
    



    <div class="row">
      <div
      
      v-for="(item, index) in filtredList()"
      :key="index"
      :style=""
      :class="styleSublisteMode + isCurrent(index === showList, showCmds === item._id)"
      v-if="((__idxOnEdition === -1 && showCmds === -1) || (__idxOnEdition === index) || (showCmds === item._id))"
    >

    <div class="row" style="align-items: center;">
      <div class="col-md-2">{{ item.stationeName }}</div>
      <div class="col-md-1">
        <user style="float: right" :PID="item._id" TYPE="AGENT" :LOGIN="item.tel"></user>
      </div>
      <div class="col-md-6">
        <div v-if="showMenu !== index" >{{ item.name }}</div>
        <div v-if="showMenu === index">
          <h4 style="margin-bottom: 5px">{{ item.name }}</h4>
          {{ item.email }}<br>
          {{ item.tel }}<br>
        </div>

      </div>
      
      <div class="col-md-3">

        <div class="menu"  v-if="showCmds !== -1">
            <b-button size="sm" variant="danger" @click="showCmds = -1">
            Retour
          </b-button>
        </div>

        <div class="menu" v-if="showCmds === -1">
        <ico-btn
          size="p"
          type="simple"
          icone="gear-fill"
          :cb="
            () => {
              dispatchEdit(item._id);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          type="warning"
          icone="trash"
          :cb="
            () => {
              dispatchDelete(item._id);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          alt="info"
          type="toggleOn"
          icone="info-circle-fill"
          :cb="
            () => {
              toggleMenu(index);
            }
          "
        ></ico-btn>
        <ico-btn
          size="p"
          type="simple"
          icone="list-check"
          :cb="
            () => {
              showCmds = item._id;
            }
          "
        ></ico-btn>
      </div>
      
      </div>
    </div>
      

    </div>
    </div>

    <cmds v-if="showCmds !== -1" :byAgent="showCmds"></cmds>
  </b-overlay>
</template>
<script>
import user from '@/pages/utilisateurs/index';
import cmds from '@/pages/cmds/index';
export default {
  components: {
    user,
    cmds
  },
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },

  data() {
    return {
      filterContainer: { filter_stationID: '', keySearch: '' },
      structure: { type: [], stations: []},
      fullList: [],
      showMenu: -1,
      showCmds: -1,
      showList: -1,
      showFilter: true,
    };
  },
  methods: {
    getStationName(id) {
      if(this.structure.stations) {
        var res = this.structure.stations.filter(s => s.value === id)
        return res[0].text
      }
      return  'No';
    },

    filtredList() {
      var ret = this.fullList;
      if(this.filterContainer.filter_stationID)
        ret = ret.filter(e => e.stationID === this.filterContainer.filter_stationID)
      if(this.filterContainer.keySearch)
        ret = ret.filter(e => e.name.includes(this.filterContainer.keySearch))
      return ret
    },

    setStationsData(list){
      this.structure.stations = list
    },

    // setFilterSubData() {
    //   this.filterContainer.filter_subcat = ''
    //   if (this.filterContainer.filter_cat !== '') {
    //     this.categorySubListStructure = {};
    //     this.categorySubListStructure.grp = {
    //       type: "DD",
    //       name: "",
    //     };
    //     this.categorySubListStructure.list = [{ text: '................', value: '', extra: '' }].concat(this.fullCatList
    //       .filter((e) => e.pId === this.filterContainer.filter_cat)
    //       .map((c) => ({ text: c.name, value: c._id })));
    //     this.categorySubListStructure.editable =
    //       this.categorySubListStructure.list.length > 0;
    //   } else {
    //     this.categorySubListStructure = {list: [], grp: {type: "DD"}};
    //   }
    // },

    // setCatFilterList() {
    //   if (this.$props.__catList.length === 0) return;
    //   this.fullCatList = this.$props.__catList;
    //   this.categoryListStructure = {};
    //   this.categoryListStructure.editable = true;
    //   this.categoryListStructure.grp = {
    //     type: "DD",
    //     name: "Catégorie",
    //   };
    //   this.categoryListStructure.list = [{ text: '................', value: '', extra: '' }].concat(this.$props.__catList
    //     .filter((e) => e.pId === "0")
    //     .map((c) => ({ text: c.name, value: c._id, extra: c.type })));
    //   this.setFilterSubData();
    // },

    isCurrent(current, showCmd) {
      var _showCmd = showCmd ? ' withSub' : '';
      if (this.showList !== -1) return current+_showCmd ? "  actif" : " flowted";
      return _showCmd;
    },

    toggleMenu(index) {
      if (this.showMenu != index) {
        this.showMenu = index;
      } else {
        this.showMenu = -1;
      }
    },

    toggleList(index) {
      if (this.showList != index) {
        this.showList = index;
      } else {
        this.showList = -1;
      }
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx);
          }
        });
    },

    dispatchAdd() {
      this.$parent.addAction();
    },

    getTof(c) {
      // return "http://localhost:5000/uploads/" + c.split("/uploads/")[1];
      // return "./uploads/" + c.split("/uploads/")[1];
    },

    formatList() {
      this.fullList = this.fullList.map(e => {
        return {
          ...e,
          stationeName: this.getStationName(e.stationID)
        }
      })
    }

  },
  
  computed: {
    styleSublisteMode() {
      return this.__editMode !== true ? "listeMode col-md-12" : "editMode col-md-12";
    },
  },

  watch: {
    __list: {
      deep: true,
      handler(newValue, oldValue) {
        this.fullList = this.$props.__list ;
        this.formatList()
      },
    },
    structure: {
      deep: true,
      handler(newValue, oldValue) {
        console.log("newValue")
        this.formatList()
      },
    },
  },
  mounted() {
    
    //this.formatList()
  },
};
</script>
<style scoped>
.editMode {
  float: left;
  padding: 3%;
  margin-bottom: 10px;
  border-radius: 30px;
  min-height: 40px;
  min-width: 100%;
  background-color: #fff;
}
.editMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}
.listeMode {
  padding: 3%;
  margin-bottom: 20px;
  border-radius: 30px;
  min-height: 50px;
  background-color: #fff;
}
.listeMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}

.withSub {
  background-color: #8a9fff;
  color: black !important;
}
.withSub .menu {
  background-color: #8a9fff !important;
}

.pic {
  width: 17%;
  margin-right: 3%;
  float: left;
}
.pic img {
  border-radius: 50%;
}
.preview {
  float: left;
  font-weight: bolder;
}

.preview em {
  font-weight: lighter;
  font-size: 12px;
}

.flowted {
  filter: blur(3.5px);
}

.actif {
  filter: none;
  background-color: #131016;
}

.shop {
  margin-right: 5px;
  color: #2bffc6;
}
.service {
  margin-right: 5px;
  color: #ff8d72;
}
.lubrifiant {
  margin-right: 5px;
  color: #ffd600;
}

.carburant {
  margin-right: 5px;
  color: #b1cf0c;
}

.serachPanel {
  padding: 3%;
  background-color: #171f3c;
  border-radius: 30px;
  margin:20px 0;
}
</style>
