<template>
 <List
        :__list="dataArray"
        :__isOnLoad="isListOnload"
      ></List>
</template>
<script>
import Edit from './Edit';
import List from './List';
export default {
  components: {
    Edit,
    List,
  },
  props: {
    PID: {
      type: String,
      default: () => {
        return '';
      },
    },

    LOGIN: {
      type: String,
      default: () => {
        return '';
      },
    },

    TYPE: {
      type: String,
      default: () => {
        return '';
      },
    },
  },

  data() {
    return {
      moduleRoute: '/admins',
      onEditionItem: {},
      dataArray: [],
      isListOnload: true,
    };
  },


  methods: {
    toggleActivateAction(id, val) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.isListOnload = true;
      let $onNext = (response) => {
        this.dataArray[idx].isActive = val;
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(this.dataArray[idx], ['_id', 'isActive']);
      data.isActive = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    deleteAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.isListOnload = true;
      let $onNext = (response) => {
        if (response.data == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      let data = this._.pick(this.dataArray[idx], ['_id']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute+"/"+data._id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    saveAction() {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.loadData(this.onEditionItem._id, null, 0);
      };

      const $onError = () => {};
      const $onComplete = () => {
        this.isListOnload = false;
      };
      let diff = ['type', 'pid', 'login', 'pwd']
      // let diff = this.__.differ(
      //   this.dataArray[0],
      //   this.onEditionItem
      // );
      diff.push('_id');
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    generatePWD() {
      var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var passwordLength = 8;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
      }

      return password
    },

    addAction() {
      this.isListOnload = true;
      let $onNext = (response) => {
        this.loadData(response.data, () => {
          this.onEditionItem = this.dataArray[0]
          this.onEditionItem.type = this.$props.TYPE;
          this.onEditionItem.pid = this.$props.PID;
          this.onEditionItem.login = this.$props.LOGIN;
          this.onEditionItem.pwd = this.generatePWD();
          this.saveAction()
        }
        );
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = response.data[0];
          } else {
            this.dataArray.push(response.data[0]);
          }

          if (cb) cb();
        } else {
          this.dataArray = response.data;
        }
      };

      let $onError = (error) => {
        this.isListOnload = false;
      };

      let $onComplete = () => {
        this.isListOnload = false;
      };

      this.isListOnload = true;
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute+ (id != null ? '/'+id : '/by/'+this.$props.PID),
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style></style>
