<template>
  <b-overlay
    :show="__isOnLoad"
    variant="white"
    rounded
    opacity="0.20"
    spinner-variant="primary"
    spinner-big
    style="float: left; width: 100%"
  >
    <div class="row serachPanel" v-if="__editMode === false">
      <div class="col-md-6">
        <base-input
          placeholder="Chercher"
          v-model="filterContainer.keySearch"
          type="string"
        >
        </base-input>
      </div>

      <div class="col-md-3">
        <selector
          :_customData="categoryListStructure"
          :_showTitle="false"
          :_parentItem="filterContainer"
          _warpper="filter_cat"
          :_cb="setFilterSubData"
        ></selector>
      </div>

      <div class="col-md-3">
        <selector
          :_customData="categorySubListStructure"
          :_showTitle="false"
          :_parentItem="filterContainer"
          _warpper="filter_subcat"
        ></selector>
      </div>
    </div>

    <div class="row">
      <div
        :class="styleSublisteMode + isCurrent(index === showList)+(item.suggested === true ? ' suggested' : '')"
        v-for="(item, index) in filtredList()"
        :key="index"
        :style=""
        v-if="__idxOnEdition === -1 || __idxOnEdition === index"
      >
        <div class="pic"><img :src="getTof(item.pic)" /></div>
        <div class="preview">
          <b-icon
            :icon="getIcon(item.catTitle)"
            :class="getClass(item.catTitle)"
          ></b-icon
          >{{ item.name }} <br /><em
            >{{ item.packID.length }} packs /
            {{ item.config[0]?.pts }} point(s)</em
          >
        </div>

        <div class="menu" v-if="__idxOnEdition !== index && showMenu == index">
          <ico-btn
            size="p"
            type="simple"
            icone="gear-fill"
            :cb="
              () => {
                dispatchEdit(item._id);
              }
            "
          ></ico-btn>
          <ico-btn
            size="p"
            type="warning"
            icone="trash"
            :cb="
              () => {
                dispatchDelete(item._id);
              }
            "
          ></ico-btn>
          <ico-btn
            size="p"
            type="toggleOn"
            icone="reply-fill"
            :cb="
              () => {
                toggleMenu(index);
              }
            "
          ></ico-btn>
        </div>
        <div
          v-if="__idxOnEdition !== index && showMenu != index && __forceCat === false"
          class="ico_param"
        >
          <b-icon
            icon="gear-fill"
            aria-hidden="true"
            @click="toggleMenu(index)"
          ></b-icon>
        </div>


        <div
          v-if="__forceCat === true"
          style="float: right"
        >
          <b-icon
            icon="basket-fill"
            aria-hidden="true"
            @click="__onSelect(item)"
            style="width: 30px; height: 30px; margin:10px"
          ></b-icon>
        </div>


      </div>
    </div>
  </b-overlay>
</template>
<script>
export default {
  components: {},
  props: {
    __idxOnEdition: {
      type: Number,
      default: () => {
        return -1;
      },
    },
    __isOnLoad: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    __catList: {
      type: Array,
      default: () => {
        return [];
      },
    },

    __forceCat: {
      type: Boolean,
      default: () => {
        return false;
      },
    },

    __suggestions: {
      type: Array,
      default: () => {
        return [];
      },
    },

    
    __onSelect: {
        type: Function,
        default: () => {()=>{}},
      },
  },

  data() {
    return {
      filterContainer: { filter_cat: "", filter_subcat: "", keySearch: "" },
      categorySubListStructure: null,
      categoryListStructure: null,
      fullCatList: [],
      showMenu: -1,
      showList: -1,
    };
  },
  methods: {
    filtredList() {
      var ret = this.__list;
      if (this.filterContainer.filter_cat)
        ret = ret.filter((e) => e.catID === this.filterContainer.filter_cat);
      if (this.filterContainer.filter_subcat)
        ret = ret.filter(
          (e) => e.subcatID === this.filterContainer.filter_subcat
        );
      if (this.filterContainer.keySearch)
        ret = ret.filter((e) =>
          e.name.includes(this.filterContainer.keySearch)
        );
      if (
        this.$props.__forceCat === true &&
        this.$props.__suggestions.length > 0
      ) {
        var withSuggestion = ret
          .filter((e) => {
            return this.$props.__suggestions[0].includes(e._id);
          })
          .map((c) => {return { ...c, suggested: true }});

        var withOutSuggestion = ret.filter(
          (e) => !this.$props.__suggestions[0].includes(e._id)
        );
        return withSuggestion.concat(withOutSuggestion);
      }
      return ret;
    },

    setFilterSubData() {
      this.filterContainer.filter_subcat = "";
      if (this.filterContainer.filter_cat !== "") {
        this.categorySubListStructure = {};
        this.categorySubListStructure.grp = {
          type: "DD",
          name: "",
        };
        this.categorySubListStructure.list = [
          { text: "................", value: "", extra: "" },
        ].concat(
          this.fullCatList
            .filter((e) => e.pId === this.filterContainer.filter_cat)
            .map((c) => ({ text: c.name, value: c._id }))
        );
        this.categorySubListStructure.editable =
          this.categorySubListStructure.list.length > 0;
      } else {
        this.categorySubListStructure = { list: [], grp: { type: "DD" } };
      }
    },

    setCatFilterList() {
      if (this.$props.__catList.length === 0) return;
      this.fullCatList = this.$props.__catList;
      this.categoryListStructure = {};
      this.categoryListStructure.editable = true;
      this.categoryListStructure.grp = {
        type: "DD",
        name: "Catégorie",
      };
      var dotSelect =
        this.$props.__forceCat === true
          ? []
          : [{ text: "................", value: "", extra: "" }];
      this.categoryListStructure.list = dotSelect.concat(
        this.$props.__catList
          .filter((e) => e.pId === "0")
          .map((c) => ({ text: c.name, value: c._id, extra: c.type }))
      );

      if (this.$props.__forceCat === true)
        this.filterContainer.filter_cat =
          this.categoryListStructure.list[0].value;
      this.setFilterSubData();
    },

    isCurrent(current) {
      if (this.showList !== -1) return current ? "  actif" : " flowted";
      return "";
    },

    toggleMenu(index) {
      if (this.showMenu != index) {
        this.showMenu = index;
      } else {
        this.showMenu = -1;
      }
    },

    toggleList(index) {
      if (this.showList != index) {
        this.showList = index;
      } else {
        this.showList = -1;
      }
    },

    dispatchEdit(idx) {
      this.$parent.editAction(idx);
    },

    dispatchDelete(idx) {
      this.$bvModal
        .msgBoxConfirm("Veuillez confirmer la suppression.", {
          title: "Confirmation",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.$parent.deleteAction(idx);
          }
        });
    },

    dispatchAdd() {
      this.$parent.addAction();
    },

    getTof(c) {
      return c ? this.__.getAppPath()+(c.split('./').join('')) : '';
      // return "http://localhost:5000/uploads/" + c.split("/uploads/")[1];
      // return "./uploads/" + c.split("/uploads/")[1];
    },

    getIcon(type) {
      switch (type) {
        case "Shop":
          return "shop";
        case "Service":
          return "tools";
        case "Carburant":
          return "speedometer";
        case "Lubrifiant":
          return "wrench";
      }
      return "shop";
    },

    getClass(type) {
      switch (type) {
        case "Shop":
          return "shop";
        case "Service":
          return "service";
        case "Carburant":
          return "carburant";
        case "Lubrifiant":
          return "lubrifiant";
      }
      return "shop";
    },
  },
  watch: {
    __isOnLoad: function () {},
    __catList: function () {
      this.setCatFilterList();
    },
  },
  computed: {
    styleSublisteMode() {
      return this.__editMode !== true
        ? "listeMode col-md-3"
        : "editMode col-md-3";
    },
  },
  mounted() {
    this.setCatFilterList();
  },
};
</script>
<style scoped>
.editMode {
  float: left;
  padding: 3%;
  margin-bottom: 10px;
  border-radius: 30px;
  min-height: 40px;
  min-width: 100%;
  background-color: #fff;
}
.editMode .menu {
    border-radius: 30px;
    background-color: #e9e9e9;
    padding: 10px;
    float: right;
}
 @media (max-width:1024px) {
  .editMode {
  float: left;
  padding: 10%;
  margin-bottom: 10px;
  border-radius: 30px;
  min-height: 40px;
  min-width: 100%;
  background-color: #fff;
}
}
.listeMode {
padding: 2%;
margin-bottom: 20px;
border-radius: 50px;
min-height: 60px;
background-color: #f9f9f9;
margin-left: 20px;
min-width: 30% !important;
}
.suggested {
  background-color: #8ae0f3;
}

.listeMode .menu {
    float: right;
    border-radius: 20px;
    background-color: #e9e9e9;
    padding: 15px 7px 15px 15px;
}
.pic {
  width: 20%;
}
.pic img {
border-radius: 50%;
}
.preview {
    float: left;
    font-weight: 700;
    font-size: x-large;
    text-transform: uppercase;
    margin: 5% 0;
}
 @media (max-width:1024px) {
  .preview {
    float: left;
    font-weight: 600;
    font-size: small;
    text-transform: uppercase;
 }
 }
.ico_param{
    margin-right: 10px;
    position: absolute;
    bottom: 30px;
    right: 30px;
}
.plus_pram
{
    position: absolute;
    top: 25px;
    right: 25px;
}
svg.bi-gear-fill.b-icon.bi{
        font-size: x-large;
}

.preview em {
  font-weight: 400;
  font-size: 10px;
}

.flowted {
  filter: blur(3.5px);
}
.actif {
  filter: none;
  background-color: #131016;
}
.shop {
  margin-right: 10px;
  color: #E6302F;
}
 @media (max-width:1024px) {
.shop {
  margin-right: 5px;
  color: #E6302F;
  font-size: medium
}
}
.service {
  margin-right: 5px;
  color: #E6302F;
}
.lubrifiant {
  margin-right: 5px;
  color: #E6302F;
}
.carburant {
  margin-right: 5px;
  color: #E6302F;
}
.serachPanel {
    padding: 40px 25px 30px 25px;
    background-color: #171f3c;
    border-radius: 30px;
    margin: 0 0 30px 0;
}
</style>
