<template>
  <div class="row">
    <div class="col-md-8" v-if="onEditionId !== null">
      <Edit
        :__item="onEditionItem"
        :__isOnLoad="isEditOnload"
        :__catList="catList"
      ></Edit>
    </div>
    <ico-btn class="btn-plus-pos" type="simple" icone="plus-circle" :cb="()=>{addAction()}" v-if="modeAchat === ''"></ico-btn>
    <div :class="listStyle">
      <List
        :__list="dataArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idxOnEdition="this.onEditionIdx"
        :__catList="catList"
        :__forceCat="modeAchat !== ''"
        :__suggestions="suggestion"
        :__onSelect="onSelect"
        ></List>
    </div>
  </div>
</template>
<script>
import Edit from './Edit';
import List from './List';
export default {
  components: {
    Edit,
    List,
  },
  props: {
    modeAchat:  {
      type: String,
      default: () => {
        return '';
      },
    },

    suggestion: {
      type: Array,
      default: () => {
        return [];
      },
    },

    onSelect: {
        type: Function,
        default: () => {()=>{}},
      },
  },

  data() {
    return {
      moduleRoute: '/prods',
      onEditionId: null,
      onEditionIdx: -1,
      onEditionItem: null,
      isListOnload: false,
      isEditOnload: false,
      dataArray: [],
      onGoingReq:[],
      catList: []
    };
  },

  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-3' : 'col-md-12';
    },
  },

  methods: {
    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
    },

    saveAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };
      
      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      let diff = this.__.differ(
        this.dataArray[this.onEditionIdx],
        this.onEditionItem
      );
      diff.push('_id');
      let data = this._.pick(this.onEditionItem, diff);
      var toSetInTpl = [];
      if(!this.onEditionItem.template || this.onEditionItem.template.length === 0) {
        var catTitle = this.onEditionItem.catTitle;
        toSetInTpl = [{
            flagName: 'Quantité',
            flagID: 'qte',
            flagValue: 1,
            flagType: 'IMP_NUMBER',
            flagVisible: catTitle !== 'Service'
          }];

        if(['Carburant'].includes(catTitle)) {
          toSetInTpl.push({
            flagName: 'Pompe',
            flagID: 'pompe',
            flagValue: '',
            flagType: 'SELECTOR',
            flagConf: '665f320ddb80b37309d91cb6',
            flagVisible: true,
          })
        }
      }
      
      data.template = [...toSetInTpl, ...this.onEditionItem.template || []]
      
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    toggleActivateAction(id, val) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.dataArray[idx].isActive = val;
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id', 'isActive']);
      data.isActive = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    deleteAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        if (response.data == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute+"/"+data._id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    editAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx)
      this.onEditionId = id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    addAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(response.data, () =>
          this.editAction(response.data)
        );
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = response.data[0];
          } else {
            this.dataArray.push(response.data[0]);
          }

          if (cb) cb();
        } else {
          this.dataArray = response.data;
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute+ (id != null ? '/'+id : '/'),
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    
    loadCategory() {
        let $onNext = (response) => {
          this.catList = (this.$props.modeAchat !== '') ? response.data.filter(e=> e.type === this.$props.modeAchat) : response.data;
          this.loadData();
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        this.isOnLoad = true;
        this.__.httpAction({
          methode: 'GET',
          route: '/prd_cats',
          data: {},
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      },
  },
  mounted() {
    
    this.loadCategory();
  },

  watch: {
    onGoingReq: function () {
      this.isListOnload = this.isEditOnload = this.onGoingReq.length > 0;
    },
  },
};
</script>
<style></style>
