<template>
  <div class="row __container">
    <div class="col-md-12" v-if="onEditionId == null">
     <ico-btn style="float: right; margin-bottom:5px" type="simple" icone="plus-circle" :cb="()=>{addAction()}"></ico-btn>
    </div>
    <div class="col-md-12" v-if="onEditionId !== null">
      <Edit
        :__item="onEditionItem"
        :__isOnLoad="isEditOnload"
      ></Edit>
    </div>
    <div :class="listStyle">
      <List
        :__list="dataArray"
        :__isOnLoad="isListOnload"
        :__editMode="this.onEditionId != null"
        :__idxOnEdition="this.onEditionIdx"
        :__type="this.TYPE"
      ></List>
    </div>
    
    <div v-if="this.onShowSubId !== null" class="col-md-6">
      <recOpts :CID="this.onShowSubId"></recOpts>
    </div>

  </div>
</template>
<script>
import Edit from './Edit';
import List from './List';
import recOpts from '@/pages/opts_sub/index';
export default {
  components: {
    Edit,
    List,
    recOpts,
  },
  props: {
    PID: {
      type: String,
      default: () => {
        return '';
      },
    },
    CID: {
      type: String,
      default: () => {
        return undefined;
      },
    },
    TYPE: {
      type: String,
      default: () => {
        return 'DD';
      },
    },
  },

  data() {
    return {
      moduleRoute: '/opts',
      onEditionId: null,
      onEditionIdx: -1,
      onEditionItem: null,
      isListOnload: false,
      isEditOnload: false,
      dataArray: [],
      onGoingReq:[],
      onShowSubId: null,
    };
  },

  computed: {
    listStyle() {
      return this.onShowSubId !== null ? 'col-md-6' : 'col-md-12';
    },
  },

  methods: {
    showSub(id) {
      this.onShowSubId = id;
    },

    cancelAction() {
      this.onEditionId = null;
      this.onEditionIdx = -1;
      this.onEditionItem = null;
    },

    saveAction() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(this.onEditionId, null, this.onEditionIdx);
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.dataArray[this.onEditionIdx].params = {};
      console.log(this.dataArray[this.onEditionIdx], this.onEditionItem)
      let diff = this.__.differ(
        this.dataArray[this.onEditionIdx],
        this.onEditionItem
      );
      diff.push('_id');
      let data = this._.pick(this.onEditionItem, diff);
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    toggleActivateAction(id, val) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.dataArray[idx].isActive = val;
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id', 'isActive']);
      data.isActive = val;
      this.updateAction({ $onNext, $onError, $onComplete, data });
    },

    deleteAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx);
      this.onGoingReq.push({});
      let $onNext = (response) => {
        if (response.data == 'OK') {
          this.dataArray.splice(idx, 1);
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      let data = this._.pick(this.dataArray[idx], ['_id']);
      this.__.httpAction({
        methode: 'DELETE',
        route: this.moduleRoute+"/"+data._id,
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    editAction(id) {
      const findIdx = (element) => element._id == id;
      var idx = this.dataArray.findIndex(findIdx)
      this.onEditionId = id;
      this.onEditionIdx = idx;
      this.onEditionItem = this._.omit(this.dataArray[idx]);
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: this.moduleRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    addAction(PID) {
      if(this.PID == undefined )
        return;
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.loadData(response.data, () =>
          this.editAction(response.data)
        );
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };
      this.__.httpAction({
        methode: 'PUT',
        route: this.moduleRoute,
        data: {parentID:this.PID},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadData(id = null, cb = null, putAt = null) {
      let $onNext = (response) => {
        if (id != null) {
          if (putAt != null) {
            this.dataArray[putAt] = response.data[0];
          } else {
            this.dataArray.push(response.data[0]);
          }

          if (cb) cb();
        } else {
          this.dataArray = response.data;
        }
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.onGoingReq.push({});
      this.__.httpAction({
        methode: 'GET',
        route: this.moduleRoute+ (id != null ? '/'+id : '/list/'+this.PID),
        data: {},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  mounted() {
    this.loadData();
  },

  watch: {
    onGoingReq: function () {
      this.isListOnload = this.isEditOnload = this.onGoingReq.length > 0;
    },
  },
};
</script>
<style scoped>
.__container {
  clear: both;
  border-radius: 30px;
  padding: 2%;
  margin:7% 0 0;
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #e7e7e7;
}
</style>
